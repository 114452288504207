import React from 'react';
import { Button, FormControl, FormLabel } from 'react-bootstrap';
import { ReactComponent as Back } from './images/back.svg';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

class Employee extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            valid: {
                name: true,
                email: true,
            },
            validationOn: false,
        }
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    checkNeedToUpdateValid(valid) {
        let sv = this.state.valid;

        return (
            valid.name !== sv.name ||
            valid.email !== sv.email
        );
    }

    checkValid() {
        let state = this.state;

        let valid = {
            name: (state.name.length > 0),
            email: (emailRegex.test(state.email)),
        };

        if (this.checkNeedToUpdateValid(valid)) {
            this.setState({valid: valid});
        } 

        return (valid.name && valid.email);
    }

    getFormatted() {
        let s = this.state;

        return {
            name: s.name,
            email: s.email,
        }
    }

    handleContinue() {
        if (this.checkValid()) {
            this.props.onNext(this.getFormatted());
        }
        this.setState({validationOn: true});
    }

    componentDidUpdate() {
        if (this.state.validationOn) {
            this.checkValid();
        }
    }

    render() {
        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                <div className="container my-5">
                    <h1>Covid-19 employee screening</h1>
                    <Button
                        variant="link"
                        size="lg"
                        className="back-button"
                        onClick={this.props.onBack.bind(this)}
                    ><Back />Back to previous page</Button>
                    <h1>Employee Information</h1>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-name"
                            >First and Last Name</FormLabel>
                            <FormControl 
                                id="job-name"
                                placeholder="FirstName LastName"
                                value={this.state.name}
                                onChange={this.handleChangeName.bind(this)}
                            />
                            { !this.state.valid.name && (
                                <span className="text-danger">Name must not be empty</span>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-email"
                            >Email</FormLabel>
                            <FormControl 
                                id="job-email"
                                type="email"
                                placeholder="you@example.com"
                                value={this.state.email}
                                onChange={this.handleChangeEmail.bind(this)}
                            />
                            { !this.state.valid.email && (
                                <span className="text-danger">Email format is invalid</span>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <Button
                            size="lg"
                            className="px-5"
                            onClick={this.handleContinue.bind(this)}
                        >Continue</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Employee;