import React from 'react';
import ResultAPI from '../ResultAPI';
import Appointment from './pages/Appointment';
import Landing from './pages/Landing';
import QuestionsYN from './pages/QuestionsYN';
import Result from './pages/Result';
import Symptoms from './pages/Symptoms';
import Vaccinated from './pages/Vaccinated';
import './Screening.css';

const startPage = "landing";
const debug = false;

const questionsVaccinated = [
    {
        question: "In the last 14 days, have you travelled outside of Canada and been told to quarantine (per the federal quarantine requirements)?",
        subtext: "",
        reason: "in the last 14 days, you travelled outside of Canada and been told to quarantine",
        correct: "No"
    },
    {
        question: "Has a doctor, health care provider, or public health unit told you that you should currently be isolating (staying at home)?",
        subtext: "This can be because of an outbreak or contact tracing.",
        reason: "a doctor, health care provider, or public health unit told you that you should currently be isolating (staying at home)",
        correct: "No"
    },
    {
        question: "In the last 10 days, have you tested positive on a rapid antigen test or home-based self-testing kit?",
        subtext: "If you have since tested negative on a lab-based PCR test, select \"No.\"",
        reason: "in the last 10 days, you tested positive on a rapid antigen test or home-based self-testing kit",
        correct: "No"
    },
    {
        question: "Has a doctor, health care provider, or public health unit told you that you or someone in your household should currently be self isolating?",
        subtext: "",
        reason: "a doctor, health care provider, or public health unit told you that you or someone in your household should currently be self isolating",
        correct: "No"
    },
    {
        question: "In the last 14 days, have you or someone in your household received a Covid Alert exposure notification on your cell phone?",
        subtext: "If you already went for a test and got a negative result, select \"No.\"",
        reason: "in the last 14 days, you or someone in your household received a Covid Alert exposure notification on your cell phone",
        correct: "No"
    },
    {
        question: (<div><u><b>I agree,</b></u> and those present, on the day of service:</div>),
        subtext: (
            <ul>
                <li className="pt-2">To minimize the number of people on the premises during the time, Klear View Window Cleaners will be onsite. We asked that only your household's residence be present, no guests, no visitors, or other tradespeople.</li>
                <li className="pt-2">To practice social distancing, inside and outside, maintain a minimum of 2 meters from all Klear View Window Cleaners employees.</li>
                <li className="pt-2">To wear a face-covering/mask while in the same room as a Klear View Window Cleaners employee.</li>
            </ul>
        ),
        reason: "you don't agree to follow the guidelines set out by Klear View Window Cleaners",
        correct: "Yes"
    },
];

const questionsUnvaccinated = [
  {
      question: "Is anyone you live with currently experiencing any new COVID-19 symptoms and/or waiting for test results after experiencing symptoms?",
      subtext: "If the person got a COVID-19 vaccine in the last 48 hours and is experiencing a mild headache, fatigue, muscle aches, and/or joint pain that only began after vaccination, select \"No.\"",
      reason: "someone you live with currently experiencing any new COVID-19 symptoms and/or waiting for test results after experiencing symptoms",
      correct: "No"
  },
  {
      question: "In the last 14 days, have you travelled outside of Canada and been told to quarantine (per the federal quarantine requirements)?",
      subtext: "",
      reason: "in the last 14 days, have you travelled outside of Canada and been told to quarantine",
      correct: "No"
  },
  {
      question: "In the last 10 days, have you been identified as a “close contact” of someone who currently has COVID-19?",
      subtext: "If public health has advised you that you do not need to self-isolate, select \"No.\"",
      reason: "in the last 10 days, have you been identified as a “close contact” of someone who currently has COVID-19",
      correct: "No"
  },
  {
      question: "Has anyone you live with:",
      subtext: (
        <div>
          <ul><li>travelled outside of Canada <b>and</b> been told to quarantine (per the federal quarantine requirements) in the last 14 days?</li></ul>
          <p><b>or</b></p>
          <ul><li>been identified as a “close contact” of someone who currently has COVID-19 <b>and</b> been told to self-isolate by a doctor, healthcare provider, or public health unit in the last 10 days?</li></ul>
        </div>
      ),
      reason: "someone you live with has travelled outside of Canada and been told to quarantine or has been identified as a “close contact” of someone who currently has COVID-19 and been told to self-isolate by a doctor, healthcare provider, or public health unit in the last 10 days",
      correct: "No"
  },
  {
    question: "Has a doctor, health care provider, or public health unit told you that you should currently be isolating (staying at home)?",
    subtext: "This can be because of an outbreak or contact tracing.",
    reason: "a doctor, health care provider, or public health unit told you that you should currently be isolating (staying at home)",
    correct: "No"
  },
  {
      question: "In the last 10 days, have you tested positive on a rapid antigen test or home-based self-testing kit?",
      subtext: "If you have since tested negative on a lab-based PCR test, select \"No.\"",
      reason: "in the last 10 days, you tested positive on a rapid antigen test or home-based self-testing kit",
      correct: "No"
  },
  {
      question: "In the last 14 days, have you or someone in your household received a Covid Alert exposure notification on your cell phone?",
      subtext: "If you already went for a test and got a negative result, select \"No.\"",
      reason: "in the last 14 days, you or someone in your household received a Covid Alert exposure notification on your cell phone",
      correct: "No"
  },
  {
      question: (<div><u><b>I agree,</b></u> and those present, on the day of service:</div>),
      subtext: (
          <ul>
              <li className="pt-2">To minimize the number of people on the premises during the time, Klear View Window Cleaners will be onsite. We asked that only your household's residence be present, no guests, no visitors, or other tradespeople.</li>
              <li className="pt-2">To practice social distancing, inside and outside, maintain a minimum of 2 meters from all Klear View Window Cleaners employees.</li>
              <li className="pt-2">To wear a face-covering/mask while in the same room as a Klear View Window Cleaners employee.</li>
          </ul>
      ),
      reason: "you don't agree to follow the guidelines set out by Klear View Window Cleaners",
      correct: "Yes"
  },
];

class Screening extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: startPage,
            approved: false,
            reason: "Error",
            date: "",
            address: "",
            vaccinated: null,
            appointment: null,
            symptoms: null,
        }
    }

    handleChangePage(page) {
        if (page === "") page = "landing";

        this.setState({page: page});
    }

    handleAppointment(appointment) {
        this.setState({appointment: appointment});
        this.setState({date: appointment.dateOfService});
        this.setState({address: appointment.address});
        this.handleChangePage("vaccinated");   
    }

    handleVaccinated(answer) {
      this.setState({vaccinated: answer});
      this.handleChangePage("symptoms");
      console.log(answer)
    }

    handleSymptoms(symptoms) {
        if (symptoms.good) {
            this.setState({symptoms: symptoms});

            if (this.state.vaccinated === "Yes")
              this.handleChangePage("questionsVaccinated");
            else 
              this.handleChangePage("questionsUnvaccinated");

        } else {
            this.loadResultBad("you or someone in your household is experiencing Covid-19 symptoms");
        }  
    }

    handleQuestionsGood() {
        this.loadResultGood();
    }

    handleQuestionsBad(reason) {
        this.loadResultBad(reason);
    }

    loadResultGood() {
        this.setState({approved: true});
        this.handleChangePage("result");
        ResultAPI.sendResultGood(this.state.appointment, this.state.vaccinated);
    }

    loadResultBad(reason) {
        this.setState({approved: false});
        this.setState({reason: reason});
        this.handleChangePage("result");
        ResultAPI.sendResultBad(this.state.appointment, reason, this.state.vaccinated);
    }

    render() {

        return(
            <div>
                <Landing
                    show={this.state.page === "landing"}
                    onNext={this.handleChangePage.bind(this, "appointment")}
                />
                <Appointment 
                    show={this.state.page === "appointment"}
                    onNext={this.handleAppointment.bind(this)}
                    onBack={this.handleChangePage.bind(this, "landing")}
                />
                <Vaccinated 
                  show={this.state.page === "vaccinated"}
                  onNext={this.handleVaccinated.bind(this)}
                  onBack={this.handleChangePage.bind(this, "appointment")}
                />
                <Symptoms
                    show={this.state.page === "symptoms"}
                    type="customer"
                    onNext={this.handleSymptoms.bind(this)}
                    onBack={this.handleChangePage.bind(this, "vaccinated")}
                />
                <QuestionsYN 
                    show={this.state.page === "questionsVaccinated"}
                    questions={questionsVaccinated}
                    type="customer"
                    onBack={this.handleChangePage.bind(this, "symptoms")}
                    onGood={this.handleQuestionsGood.bind(this)}
                    onBad={this.handleQuestionsBad.bind(this)}
                />
                <QuestionsYN 
                    show={this.state.page === "questionsUnvaccinated"}
                    questions={questionsUnvaccinated}
                    type="customer"
                    onBack={this.handleChangePage.bind(this, "symptoms")}
                    onGood={this.handleQuestionsGood.bind(this)}
                    onBad={this.handleQuestionsBad.bind(this)}
                />
                <Result 
                    show={this.state.page === "result"}
                    approved={this.state.approved}
                    date={this.state.date}
                    address={this.state.address}
                    reason={this.state.reason}
                />

                { debug ?
                  <div className="container text-primary test">
                    <p onClick={this.handleChangePage.bind(this, "landing")}>landing</p>
                    <p onClick={this.handleChangePage.bind(this, "appointment")}>appointment</p>
                    <p onClick={this.handleChangePage.bind(this, "vaccinated")}>vaccinated</p>
                    <p onClick={this.handleChangePage.bind(this, "symptoms")}>symptoms</p>
                    <p onClick={this.handleChangePage.bind(this, "questionsVaccinated")}>questionsVaccinated</p>
                    <p onClick={this.handleChangePage.bind(this, "questionsUnvaccinated")}>questionsUnvaccinated</p>
                    <p onClick={this.loadResultGood.bind(this)}>resultGood</p>
                    <p onClick={this.loadResultBad.bind(this)}>resultBad</p>
                  </div>
                  : null
                }
            </div>
        );
    }

}

export default Screening;