import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Back } from './images/back.svg';

class Vaccinated extends React.Component {

  render() {
    let show = this.props.show ? "d-block" : "d-none";

    return(
      <div className={show}>
        <div className="container my-5">
        <h1>Covid-19 {this.props.type} screening</h1>
            <Button
                variant="link"
                size="lg"
                className="back-button"
                onClick={this.props.onBack.bind(this)}
            ><Back />Back to previous page</Button>
            <div>
              <div>
                <h2>
                  Do any of the following apply to you?
                </h2>
                <div className="sub-heading">
                  <ul>
                    <li className="pt-2">
                      <b>I am fully vaccinated against COVID-19 </b>
                      (it has been 14 days or more since your final dose of either a two-dose or a one-dose vaccine series)
                    </li>
                    <li className="pt-2">
                      <b>I have tested positive for COVID-19 </b>
                      in the last 90 days (and since been cleared)
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <Button
                      size="lg"
                      className="px-5 mr-4"
                      onClick={this.props.onNext.bind(this, "No")}
                  >No</Button>
                  <Button
                      size="lg"
                      className="px-5 ml-4"
                      onClick={this.props.onNext.bind(this, "Yes")}
                  >Yes</Button>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    variant="link"
                    className="px-5 ml-4"
                    onClick={this.props.onNext.bind(this, "No")}
                  >Prefer not to answer</Button>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }

}

export default Vaccinated;
