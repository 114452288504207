import React from 'react';
import { Button, FormControl, FormLabel } from 'react-bootstrap';
import { ReactComponent as Back } from './images/back.svg';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

class Appointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            dateOfService: "",
            email: "",
            phone: "",
            address: "",
            todaysDate: this.getTodaysDate(),
            twoWeeks: this.getTwoWeeks(),
            valid: {
                name: true,
                dateOfService: true,
                email: true,
                phone: true,
                address: true,
            },
            validationOn: false,
        }
    }

    formatDate(date) {
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        date = `${yyyy}-${mm}-${dd}`; 
        return date;
    }

    getTodaysDate() {
        let today = new Date();
        return this.formatDate(today);
    }

    getTwoWeeks() {
        let today = new Date();
        let twoWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
        return this.formatDate(twoWeeks);
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeDateOfService(event) {
        this.setState({dateOfService: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangePhone(event) {
        this.setState({phone: event.target.value});
    }

    handleChangeAddress(event) {
        this.setState({address: event.target.value});
    }

    checkNeedToUpdateValid(valid) {
        let sv = this.state.valid;

        return (
            valid.name !== sv.name ||
            valid.dateOfService !== sv.dateOfService ||
            valid.email !== sv.email ||
            valid.phone !== sv.phone ||
            valid.address !== sv.address
        );
    }

    checkValid() {
        let state = this.state;

        let valid = {
            name: (state.name.length > 0),
            dateOfService: (state.dateOfService !== ""),
            email: (emailRegex.test(state.email)),
            phone: (phoneRegex.test(state.phone)),
            address: (state.address.length > 0),
        };

        if (this.checkNeedToUpdateValid(valid)) {
            this.setState({valid: valid});
        } 

        return (valid.name && valid.dateOfService && valid.email && valid.phone && valid.address);
    }

    getFormatted() {
        let s = this.state;

        return {
            name: s.name,
            dateOfService: s.dateOfService,
            email: s.email,
            phone: s.phone,
            address: s.address,
        }
    }

    handleContinue() {
        if (this.checkValid()) {
            this.props.onNext(this.getFormatted());
        }
        this.setState({validationOn: true});
    }

    componentDidUpdate() {
        if (this.state.validationOn) {
            this.checkValid();
        }
    }

    render() {
        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                <div className="container my-5">
                    <h1>Covid-19 customer screening</h1>
                    <Button
                        variant="link"
                        size="lg"
                        className="back-button"
                        onClick={this.props.onBack.bind(this)}
                    ><Back />Back to previous page</Button>
                    <h1>Appointment Information</h1>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-name"
                            >First and Last Name</FormLabel>
                            <FormControl 
                                id="job-name"
                                placeholder="FirstName LastName"
                                value={this.state.name}
                                onChange={this.handleChangeName.bind(this)}
                            />
                            { !this.state.valid.name && (
                                <span className="text-danger">Name must not be empty</span>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-date-of-service"
                            >Date of Service</FormLabel>
                            <FormControl 
                                id="job-date-of-service"
                                type="date"
                                min={this.state.todaysDate}
                                max={this.state.twoWeeks}
                                value={this.state.dateOfService}
                                onChange={this.handleChangeDateOfService.bind(this)}
                            />
                            { !this.state.valid.dateOfService && (
                                <span className="text-danger">Please select a date of service</span>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-email"
                            >Email</FormLabel>
                            <FormControl 
                                id="job-email"
                                type="email"
                                placeholder="you@example.com"
                                value={this.state.email}
                                onChange={this.handleChangeEmail.bind(this)}
                            />
                            { !this.state.valid.email && (
                                <span className="text-danger">Email format is invalid</span>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12 mt-3">
                            <FormLabel
                                htmlFor="job-phone"
                            >Phone</FormLabel>
                            <FormControl 
                                id="job-phone"
                                placeholder="(000) 000-0000"
                                value={this.state.phone}
                                onChange={this.handleChangePhone.bind(this)}
                            />
                            { !this.state.valid.phone && (
                                <span className="text-danger">Phone is invalid</span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <FormLabel
                            htmlFor="job-address"
                        >Address</FormLabel>
                        <FormControl 
                            id="job-address"
                            placeholder="123 Street St, City, ON, A1A 1A1"
                            value={this.state.address}
                            onChange={this.handleChangeAddress.bind(this)}
                        />
                        { !this.state.valid.address && (
                            <span className="text-danger">Address is invalid</span>
                        )}
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <Button
                            size="lg"
                            className="px-5"
                            onClick={this.handleContinue.bind(this)}
                        >Continue</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Appointment;