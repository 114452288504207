import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Back } from './images/back.svg';

class Question extends React.Component {

    render() {

        let b1 = "No";
        let b2 = "Yes";

        if (this.props.question.correct === "Yes") {
            b1 = "Yes";
            b2 = "No";
        }

        return(
            <div>
                <div>
                    <h2>
                        {this.props.question.question}
                    </h2>
                    <div className="sub-heading">
                        {this.props.question.subtext}
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <Button
                        size="lg"
                        className="px-5 mr-4"
                        onClick={this.props.onAnswer.bind(this, b1)}
                    >{b1}</Button>
                    <Button
                        size="lg"
                        className="px-5 ml-4"
                        onClick={this.props.onAnswer.bind(this, b2)}
                    >{b2}</Button>
                </div>
            </div>
        );
    }
}

class QuestionsYN extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            question: 0,
            questions: this.props.questions
        }
    }

    handleBack() {
        if (this.state.question === 0) {
            this.props.onBack();
        } else {
            this.setState({question: this.state.question - 1});
        } 
    }

    handleAnswer(answer) {
        let question = this.state.question;

        if (answer === this.state.questions[question].correct) {
            if (question < this.state.questions.length-1) {
                this.setState({question: question + 1});
            } else {
                this.props.onGood();
            }  
        } else {
            this.props.onBad(this.state.questions[question].reason);
        }
    }

    render() {
        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                <div className="container my-5">
                <h1>Covid-19 {this.props.type} screening</h1>
                    <Button
                        variant="link"
                        size="lg"
                        className="back-button"
                        onClick={this.handleBack.bind(this)}
                    ><Back />Back to previous page</Button>
                    <div>
                        <Question 
                            question={this.state.questions[this.state.question]}
                            onAnswer={this.handleAnswer.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default QuestionsYN;