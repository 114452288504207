import React from "react";
import { Button, Form, FormCheck } from "react-bootstrap";
import { ReactComponent as Back } from "./images/back.svg";
const symptoms = require("../../assets/questions/symptoms.json");

class Symptoms extends React.Component {
  constructor(props) {
    super(props);

    let checkboxes = [];
    for (let i = 0; i < symptoms.length; i++) {
      checkboxes.push({
        name: symptoms[i].symptom,
        value: false,
      });
    }
    checkboxes.push({ name: "none", value: false });

    this.state = {
      checkboxes: checkboxes,
    };
  }

  handleOnChange(event) {
    let checkboxes = this.state.checkboxes;

    for (let i = 0; i < checkboxes.length; i++) {
      if (event.target.id === "none" && checkboxes[i].name !== "none") {
        checkboxes[i].value = false;
      } else {
        if (event.target.id === checkboxes[i].name) {
          checkboxes[i].value = event.target.checked;
        }
      }
    }

    if (event.target.id !== "none") {
      checkboxes[checkboxes.length - 1].value = false;
    }

    this.setState({ checkboxes: checkboxes });
  }

  getCheckboxValue(name) {
    let checkboxes = this.state.checkboxes;

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].name === name) {
        return checkboxes[i].value;
      }
    }
    return false;
  }

  checkAllFalse() {
    let checkboxes = this.state.checkboxes;

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value === true) return false;
    }
    return true;
  }

  checkGood() {
    let checkboxes = this.state.checkboxes;

    for (let i = 0; i < checkboxes.length - 1; i++) {
      if (checkboxes[i].value === true) return false;
    }
    return true;
  }

  handleContinue() {
    if (this.checkAllFalse()) {
      let checkboxes = this.state.checkboxes;
      checkboxes[checkboxes.length - 1].value = true;
      this.setState({ checkboxes: checkboxes });
    }

    let output = {
      good: this.checkGood(),
      checkboxes: this.state.checkboxes,
    };

    this.props.onNext(output);
  }

  render() {
    let show = this.props.show ? "d-block" : "d-none";

    let questions = symptoms.map((symptom) => (
      <div key={symptom.symptom}>
        <FormCheck
          type="checkbox"
          id={symptom.symptom}
          checked={this.getCheckboxValue(symptom.symptom)}
          onChange={this.handleOnChange.bind(this)}
          label={
            <div>
              <p>
                <b>{symptom.symptom}</b>
              </p>
              <p>{symptom.subtext}</p>
            </div>
          }
        />
      </div>
    ));

    return (
      <div className={show}>
        <div className="container my-5">
          <h1>Covid-19 {this.props.type} screening</h1>
          <Button
            variant="link"
            size="lg"
            className="back-button"
            onClick={this.props.onBack.bind(this)}
          >
            <Back />
            Back to previous page
          </Button>
          <h1>
            Are you{" "}
            {this.props.type === "customer" && "or anyone in your household "}
            experiencing any of these symptoms?
          </h1>
          <p className="sub-heading">
            Choose any/all that are new, worsening, and not related to other
            known causes or conditions you already have.
          </p>

          <Form className="symptoms-form pt-3">
            {questions}

            <FormCheck
              type="checkbox"
              id="none"
              checked={this.getCheckboxValue("none")}
              onChange={this.handleOnChange.bind(this)}
              label={
                <div>
                  <p>
                    <b>None of the above</b>
                  </p>
                </div>
              }
            />
          </Form>
          <div className="d-flex justify-content-center mt-5">
            <Button
              size="lg"
              className="px-5"
              type="submit"
              onClick={this.handleContinue.bind(this)}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Symptoms;
