
const baseUrl = "https://covid.klearview.ca/php/";

export default class ResultAPI {

    static async sendResultGood(appointment, vaccinated) {
        console.log(appointment);

        if (appointment !== null) {
            
            let params = new URLSearchParams();
            params.append("name", appointment.name);
            params.append("dateOfService", appointment.dateOfService);
            params.append("email", appointment.email);
            params.append("phone", appointment.phone);
            params.append("address", appointment.address);
            params.append("vaccinated", vaccinated);

            let response = await fetch(`${baseUrl}resultGood.php`, {
                method: 'POST',
                mode: 'cors',
                body: params
            });

            let json = await response.json();
            console.log(json);
            return json;
        } else {
            return {
                success: false,
                errors: ["No appointment made, please refill out form and try again"]
            }
        }
    }

    static async sendResultBad(appointment, reason, vaccinated) {
        console.log(appointment);
        console.log(reason);

        if (appointment !== null) {

            let params = new URLSearchParams();
            params.append("name", appointment.name);
            params.append("dateOfService", appointment.dateOfService);
            params.append("email", appointment.email);
            params.append("phone", appointment.phone);
            params.append("address", appointment.address);
            params.append("reason", reason);
            params.append("vaccinated", vaccinated);

            let response = await fetch(`${baseUrl}resultBad.php`, {
                method: 'POST',
                mode: 'cors',
                body: params
            });

            let json = await response.json();
            console.log(json);
            return json;
        } else {
            return {
                success: false,
                errors: ["No appointment made, please refill out form and try again"]
            }
        }
    }

    static async employeeResultGood(employee, date, vaccinated) {
        console.log(employee);

        if (employee !== null) {

            let params = new URLSearchParams();
            params.append("name", employee.name);
            params.append("email", employee.email);
            params.append("date", date);
            params.append("vaccinated", vaccinated);

            let response = await fetch(`${baseUrl}employeeResultGood.php`, {
                method: 'POST',
                mode: 'cors',
                body: params
            });

            let json = await response.json();
            console.log(json);
            return json;
        } else {
            return {
                success: false,
                errors: ["No employee made, please refill out form and try again"]
            }
        }
    }

    static async employeeResultBad(employee, reason, date, vaccinated) {
        console.log(employee);
        console.log(reason);

        if (employee !== null) {

            let params = new URLSearchParams();
            params.append("name", employee.name);
            params.append("email", employee.email);
            params.append("reason", reason);
            params.append("date", date);
            params.append("vaccinated", vaccinated);

            let response = await fetch(`${baseUrl}employeeResultBad.php`, {
                method: 'POST',
                mode: 'cors',
                body: params
            });

            let json = await response.json();
            console.log(json);
            return json;
        } else {
            return {
                success: false,
                errors: ["No employee made, please refill out form and try again"]
            }
        }
    }

}