
let baseUrl = "https://covid.klearview.ca/php/";

export default class AdminAPI {

    static async getScreening(password) {

        let params = new URLSearchParams();
        params.append("password", password);

        let response = await fetch(`${baseUrl}getScreening.php`, {
            method: 'POST',
            mode: 'cors',
            body: params
        });

        let json = await response.json();
        console.log(json);
        return json;
    }
}