import React from 'react';
import good from './images/good.png';
import bad from './images/bad.png';

class EmployeeResult extends React.Component {

    getApproved() {
        return (
            <div className="result">
                <div className="result-top result-good text-center">
                    <h1>You are fit to work</h1>
                    <img src={good} alt="good" height="200px"/>
                </div>
                <div className="container mt-5">
                    <p><b>Valid for:</b> {this.props.date}</p>
                    <p>
                        If start to feel ill during the day please contact the 
                        office and we will make arrangements for you to go home.
                    </p>
                    <div className="mt-3">
                        <p>
                            Contact Information.<br/>
                            <a href="mailto:info@klearview.ca">info@klearview.ca</a><br/>
                            <a href="tel:905-634-2882">905-634-2882</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    getNotApproved() {
        return (
            <div className="result">
                <div className="result-top result-bad text-center">
                    <h1>You are <strong>NOT</strong> fit to work</h1>
                    <img src={bad} alt="bad" height="200px"/>
                </div>
                <div className="container mt-5">
                    <p>
                        Because you selected that {this.props.reason}.
                        You are not fit to work today.
                    </p>
                    <p>
                        If this is not correct, please&nbsp;
                        <a href="https://covid.klearview.ca/">restart</a>
                        &nbsp;the screening.
                    </p>
                    <p>
                        Please contact the office to inform that you are unable to work today.
                    </p>
                    <div className="mt-3">
                        <p>
                            Contact Information.<br/>
                            <a href="mailto:info@klearview.ca">info@klearview.ca</a><br/>
                            <a href="tel:905-634-2882">905-634-2882</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                {(this.props.approved) ? this.getApproved() : this.getNotApproved()}
            </div>
        );
    }
}

export default EmployeeResult;