import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import Logo from './logo.png';
import Screening from './screening/Screening';
import EmployeeScreening from './screening/EmployeeScreening';
import { Button } from 'react-bootstrap';
import Admin from './admin/Admin';

class App extends React.Component {

    render() {
        return (
            <Router>
                <div>
                    <Header />

                    <div className="main">
                        <Switch>
                            <Route exact path="/" component={Screening} />
                            <Route exact path="/employee" component={EmployeeScreening} />
                            <Route exact path="/admin" component={Admin} />
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </div>
  
                    <Footer />
                </div>
            </Router>
          );
    }
}

function Header() {
    return(
        <div className="header">
            <div className="container py-3">
                <div className="mr-auto">
                    <a href="https://www.klearview.ca/">
                        <img src={Logo} alt="Klearview.ca"/>
                    </a>
                </div>
            </div>
        </div>
    );
}

function Footer() {
    return(
        <div className="footer">
            <div className="container py-3 text-center">
                <p>Contact Information</p>
                <p><a href="mailto:info@klearview.ca">info@klearview.ca</a><br/></p>
                <p><a href="tel:905-634-2882">905-634-2882</a></p>
            </div>
        </div>
    );
}

function PageNotFound() {
    return(
        <div className="page-not-found">
            <div className="container py-3 text-center">
                <h1>Oops, This Page Could Not Be Found!</h1>
                <h2>404</h2>
                <h3>You may have been looking for:</h3>
                <div className="links">
                    <LinkContainer to="/">
                        <Button
                            size="lg"
                        >Customer Screening</Button>
                    </LinkContainer>
                    <LinkContainer to="/employee">
                        <Button
                            size="lg"
                        >Employee Screening</Button>
                    </LinkContainer>
                </div>
            </div>
        </div>
    );
}

export default App;
