import React from 'react';
import { Button } from 'react-bootstrap';

class LandingEmployee extends React.Component {
    render() {

        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                <div className="container my-5">
                    <h1>Covid-19 employee screening</h1>
                    <p className="lead-statement pt-3">
                        Employees are required to complete this screening tool each day before
                        they can start work.  
                    </p>
                    <p>
                        <b>The questions in this tool have been defined by the Ministry of Health. </b>
                    </p>
                    <p>
                        <b>This screening cannot diagnose you. </b>
                        If you have medical questions, consult a health care provider. 
                        Follow the direction of your local public health unit over the advice in this tool.
                    </p>
                    <div className="d-flex justify-content-center mt-5">
                        <Button
                            size="lg"
                            className="px-5"
                            onClick={this.props.onNext.bind(this)}
                        >Start Screening</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingEmployee;