import React from 'react';
import good from './images/good.png';
import bad from './images/bad.png';

class Result extends React.Component {

    getApproved() {
        return (
            <div className="result">
                <div className="result-top result-good text-center">
                    <h1>We can come</h1>
                    <img src={good} alt="good" height="200px"/>
                </div>
                <div className="container mt-5">
                    <p><b>Valid for services on:</b> {this.props.date}</p>
                    <p><b>Located at:</b> {this.props.address}</p>
                    <p>
                        Please contact us if any of your answers have changed (for example, you or 
                        someone in your household start experiencing symptoms) from when you completed 
                        this screening tool to the time your service is to begin to determine if 
                        we need to reschedule your appointment.
                    </p>
                    <div className="mt-3">
                        <p>
                            Contact Information.<br/>
                            <a href="mailto:info@klearview.ca">info@klearview.ca</a><br/>
                            <a href="tel:905-634-2882">905-634-2882</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    getNotApproved() {
        return (
            <div className="result">
                <div className="result-top result-bad text-center">
                    <h1>We can <b>NOT</b> come</h1>
                    <img src={bad} alt="bad" height="200px"/>
                </div>
                <div className="container mt-5">
                    <p>
                        Because you selected that {this.props.reason}.
                        We are unable to provide service to you.
                    </p>
                    <p>
                        If this is not correct, please&nbsp;
                        <a href="https://covid.klearview.ca/">restart</a>
                        &nbsp;the screening.
                    </p>
                    <p>
                        Please contact the office to cancel your appointment for {this.props.date}.  
                        We can reschedule your appointment for a later date.
                    </p>
                    <div className="mt-3">
                        <p>
                            Contact Information.<br/>
                            <a href="mailto:info@klearview.ca">info@klearview.ca</a><br/>
                            <a href="tel:905-634-2882">905-634-2882</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let show = this.props.show ? "d-block" : "d-none";

        return(
            <div className={show}>
                {(this.props.approved) ? this.getApproved() : this.getNotApproved()}
            </div>
        );
    }
}

export default Result;