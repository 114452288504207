import React from 'react';
import { Button, FormControl, Table } from 'react-bootstrap';
import AdminAPI from './AdminAPI';

class CustomerTable extends React.Component {

    getRows() {
        if (this.props.customers !== null) {
            return this.props.customers.map(c => (
                <tr key={c.dateSummited} className={c.approved === "1" ? "table-success" : "table-danger"}>
                    <td>{c.name}</td>
                    <td>{c.dateOfService}</td>
                    <td>{c.email}</td>
                    <td>{c.phone}</td>
                    <td>{c.address}</td>
                    <td>{c.vaccinated}</td>
                    <td>{c.dateSummited}</td>
                </tr>
            ));
        }
    }

    render() {
        return(
            <div>  
                <h2>Customers</h2>         
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date of Service</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Vaccinated</th>
                            <th>Date Submitted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getRows()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

class EmployeeTable extends React.Component {

    getRows() {
        if (this.props.employees !== null) {
            return this.props.employees.map(c => (
                <tr key={c.dateSummited} className={c.approved === "1" ? "table-success" : "table-danger"}>
                    <td>{c.name}</td>
                    <td>{c.email}</td>
                    <td>{c.vaccinated}</td>
                    <td>{c.dateSummited}</td>
                </tr>
            ));
        }
    }

    render() {
        return(
            <div>  
                <h2>Employees</h2>         
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Vaccinated</th>
                            <th>Date Submitted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getRows()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            error: "",
            loading: false,
            customers: null,
            employees: null
        }
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    async handleGetInfo() {
        this.setState({loading: true});
        let response = await AdminAPI.getScreening(this.state.password);
        if (response.success) {
            this.setState({error: ""});
            this.setState({customers: response.customers});
            this.setState({employees: response.employees});
        } else {
            this.setState({error: response.error});
        }
        this.setState({loading: false});
    }


    render() {
        return(
            <div className="container">
                <h1>Admin</h1>
                <span className="text-danger">{this.state.error}</span>
                <div className="row mb-5">
                    <FormControl 
                        type="password"
                        className="col-3 mr-3"
                        value={this.state.password}
                        onChange={this.handlePasswordChange.bind(this)}
                    />
                    <Button 
                        className="mr-2"
                        variant="success"
                        onClick={this.handleGetInfo.bind(this)}
                    >Get Info
                    </Button>
                </div>
                <span>{this.state.loading && "Loading..."}</span>
                <div className="mb-5">
                    <CustomerTable 
                        customers={this.state.customers}
                    />
                </div>
                <span>{this.state.loading && "Loading..."}</span>
                <div>
                    <EmployeeTable
                        employees={this.state.employees}
                    />
                </div>   
            </div>
        );
    }
}

export default Admin;